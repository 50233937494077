import type LegacyUserDataType from "@components/types/LegacyUserDataType";
import LegacyApi from "@deezer/legacy-api";
import { useQuery } from "@tanstack/react-query";

export type User = {
  isLogged?: boolean;
  user?: LegacyUserDataType;
  token?: string;
};

const api = new LegacyApi("www.deezer.com", {
  mode: "cors",
  credentials: "include",
});

const query = async (): Promise<User> => {
  return api
    .call({
      method: "deezer.getUserData",
    })
    .then((response) => {
      const user = response as LegacyUserDataType;

      const isLogged = Boolean(user.USER?.USER_ID) && user.USER?.USER_ID !== 0;

      return {
        isLogged,
        user,
      };
    });
};

const useQueryUserData = () => {
  return useQuery(["deezer.getUserData"], () => query(), {
    enabled:
      typeof window !== "undefined" && window.location.hostname !== "localhost",
    staleTime: Infinity,
  });
};

export default useQueryUserData;
