import type { DefaultTheme } from "styled-components";

const common = {
  colors: {
    primary: "#ef5466",
    primaryLight1: "#f76b7b",
    primaryLight2: "#e4495b",
    outline: "#2196F3",
    border: "#EFEFF2",
    textSecondary: "#727272",
    textSecondaryLighter: "#737373",
    black: "#000000",
    softBlack: "#111112",
    white: "#FFFFFF",
    whitesmoke: "#F7F7F5",
    disabled: "#cacaca",
    disabledSecondary: "#e0e0e0",
  },
  fontFamily: {
    deezer: '"Deezer-Product", Arial, sans-serif',
    inter: '"Inter", sans-serif',
  },
  fontSize: {
    xs: "0.8rem",
    sm: "0.9rem",
    base: "1rem",
    lg: "1.2rem",
    xl: "1.3rem",
    "2xl": "1.6rem",
    "3xl": "1.9rem",
    "4xl": "2rem",
  },
  fontWeight: {
    hairline: "100",
    thin: "200",
    light: "300",
    normal: "400",
    medium: "500",
    semibold: "600",
    bold: "700",
    extrabold: "800",
    black: "900",
  },
  spacing: {
    "0": "0px",
    "1": "1px",
    "2": "2px",
    "3": "3px",
    "5": "5px",
    "7": "7px",
    "8": "8px",
    "10": "10px",
    "15": "12px",
    "16": "16px",
    "18": "18px",
    "20": "20px",
    "25": "25px",
    "28": "28px",
    "30": "30px",
  },
  borderWidth: {
    "0": "0px",
    "1": "1px",
  },
  borderRadius: {
    "0": "0px",
    "5": "5px",
    "10": "10px",
    "50": "50px",
    circle: "100%",
  },
  fullscreen: true,
  rtl: false,
  playerRadius: true,
};

export const lightTheme = {
  name: "light",
  ...common,
  colors: {
    ...common.colors,
    text: "#191919",
    background: "#F8F8F8",
    backgroundHover: "#efefef",
  },
};

export const darkTheme = {
  name: "dark",
  ...common,
  colors: {
    ...common.colors,
    text: "#FFFFFF",
    background: "#191919",
    backgroundHover: "#2d2d2d",
    border: "#32323D",
    textSecondary: "#92929D",
    disabled: "#636363",
    disabledSecondary: "#6D6D6D",
  },
};

export type ThemeType = DefaultTheme;
